import { useState } from 'react';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import dynamic from 'next/dynamic';
import { useAuth } from 'reactfire';
import { ChevronRightIcon, ArrowLeftEndOnRectangleIcon } from '@heroicons/react/24/outline';
import { useUserSession } from '~/core/hooks/use-user-session';
import Logo from '~/core/ui/Logo';
import Container from '~/core/ui/Container';
import If from '~/core/ui/If';
import Button from '~/core/ui/Button';
import configuration from '~/configuration';
import { Trans } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

const DarkModeToggle = dynamic(() => import('~/components/DarkModeToggle'), {
  ssr: false,
});

const fixedClassName = `FixedHeader`;

const SiteHeader: React.FCC<{
  fixed?: boolean;
}> = ({ fixed }) => {
  const auth = useAuth();
  const userSession = useUserSession();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const signOutRequested = () => auth.signOut();

  return (
    <div className={`w-full ${fixed ? fixedClassName : ''}`}>
      <Container>
        <div className="flex py-1.5 px-1 items-center justify-between">
          <div className={'w-4/12'}>
            <Logo />
          </div>


          <div className="flex items-center">
            <If condition={configuration.features.enableThemeSwitcher}>
              <div>
                <DarkModeToggle />
              </div>
            </If>

            <div className={'lg:hidden'}>
              <button className="p-2 text-gray-600 dark:text-gray-300" onClick={toggleMenu}>
                {menuOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
              </button>
            </div>

            {/* Desktop Navigation */}
            <div className={'hidden lg:flex items-center space-x-4'}>
              <If condition={userSession?.auth} fallback={<AuthButtons />}>
                <LogOutButton onSignOut={signOutRequested} />
              </If>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`${
            menuOpen ? 'block' : 'hidden'
          } lg:hidden bg-white dark:bg-gray-800 shadow-md rounded-md p-4 absolute top-16 right-4 z-50`}
        >
          <If condition={!userSession?.auth}>
            <AuthButtons />
          </If>

          <If condition={userSession?.auth}>
            <LogOutButton onSignOut={signOutRequested} />
          </If>
        </div>
      </Container>
    </div>
  );
};

function AuthButtons() {
  return (
    <div className="flex flex-col items-center lg:flex-row">
      <Button round variant={'ghost'} href={configuration.paths.signIn} target="_self">
        <span>Sign In</span>
      </Button>

      <Button round href={configuration.paths.signUp} target="_self">
        <span className={'flex items-center space-x-2'}>
          <span>Sign Up</span>
          <ChevronRightIcon className={'h-4'} />
        </span>
      </Button>
    </div>
  );
}

function LogOutButton({ onSignOut }: { onSignOut: () => void }) {
  return (
    <div
      className={twMerge(
        'space-x-2 lg:flex flex items-center',
        'border-transparent transition-shadow ',
        'hover:shadow-xl',
        '!rounded-full !bg-transparent',
        'dark:border-transparent dark:shadow-primary-500/50',
      )}
    >
      <Button round variant={'custom'} type="button" onClick={onSignOut}>
        <ArrowLeftEndOnRectangleIcon className={'h-5'} />

        <span>
          <Trans i18nKey={'auth:signOut'} />
        </span>
      </Button>
    </div>
  );
}

export default SiteHeader;
